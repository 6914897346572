/* eslint-disable */
import request from './request';

/**
 * 登录
 *
 * @param data
 * @returns
 */
export async function auth(data: any) {
  return request('/authService/backend/login', {
    data,
    method: 'post',
  });
}

/**
 * 获取个人资料
 *
 * @param data
 * @returns
 */
export async function profile(data: any) {
  return request('/authService/backend/profile', {
    data,
    method: 'get',
  });
}

/**
 * 修改个人资料
 *
 * @param data
 * @returns
 */
export async function profileUpdate(data: any) {
  return request('/authService/backend/profile/update', {
    data,
    method: 'post',
  });
}
/**
 * 获取角色列表
 *
 * @param data
 * @returns
 */
export async function roleList(data: any) {
  return request('/authService/backend/role/list', {
    data,
    method: 'get',
  });
}
/**
 * 获取角色列表(分页)
 *
 * @param data
 * @returns
 */
export async function rolePaginate(data: any) {
  return request('/authService/backend/role/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 修改或编辑角色
 *
 * @param data
 * @returns
 */
export async function roleUpdateOrCreate(data: any) {
  return request('/authService/backend/role/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除角色
 *
 * @param data
 * @returns
 */
export async function roleDelete(data: any) {
  return request('/authService/backend/role/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取角色权限列表
 *
 * @param data
 * @returns
 */
export async function rolePermissionList(data: any) {
  return request('/authService/backend/role/permission/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取用户列表
 *
 * @param data
 * @returns
 */
export async function userPaginate(data: any) {
  return request('/authService/backend/user/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取用户列表
 *
 * @param data
 * @returns
 */
export async function userList(data: any) {
  return request('/authService/backend/user/list', {
    data,
    method: 'get',
  });
}

/**
 * 修改或编辑用户
 *
 * @param data
 * @returns
 */
export async function userUpdateOrCreate(data: any) {
  return request('/authService/backend/user/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除用户
 *
 * @param data
 * @returns
 */
export async function userDelete(data: any) {
  return request('/authService/backend/user/delete', {
    data,
    method: 'post',
  });
}

/**
 * 编辑用户备注
 *
 * @param data
 * @returns
 */
export async function userUpdateRemark(data: any) {
  return request('/authService/backend/user/updateRemark', {
    data,
    method: 'post',
  });
}

/**
 * 修改系统设置
 *
 * @param data
 * @returns
 */
export async function systemSettingUpdate(data: any) {
  return request('/apiService/backend/systemSetting/update', {
    data,
    method: 'post',
  });
}

/**
 * 获取系统设置
 *
 * @param data
 * @returns
 */
export async function systemSettingGet(data: any) {
  return request('/apiService/backend/systemSetting/get', {
    data,
    method: 'post',
  });
}

/**
 * 获取OSS配置
 *
 * @param data
 * @returns
 */
export async function commonOssStsTokenGet(data: any) {
  return request('/apiService/backend/oss/stsToken', {
    data,
    method: 'get',
  });
}

/**
 * 获取评价配置
 *
 * @param data
 * @returns
 */
export async function evaluationSettingList(data: any) {
  return request('/apiService/backend/evaluationSetting/list', {
    data,
    method: 'get',
  });
}

/**
 * 编辑评价配置
 *
 * @param data
 * @returns
 */
export async function evaluationSettingUpdateOrCreate(data: any) {
  return request('/apiService/backend/evaluationSetting/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 编辑评价配置
 *
 * @param data
 * @returns
 */
export async function evaluationSettingDelete(data: any) {
  return request('/apiService/backend/evaluationSetting/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取证书配置
 *
 * @param data
 * @returns
 */
export async function certificateSettingList(data: any) {
  return request('/apiService/backend/certificateSetting/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取证书配置(分页)
 *
 * @param data
 * @returns
 */
export async function certificateSettingPaginate(data: any) {
  return request('/apiService/backend/certificateSetting/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 编辑证书配置
 *
 * @param data
 * @returns
 */
export async function certificateSettingUpdateOrCreate(data: any) {
  return request('/apiService/backend/certificateSetting/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 编辑证书配置
 *
 * @param data
 * @returns
 */
export async function certificateSettingDelete(data: any) {
  return request('/apiService/backend/certificateSetting/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取邀请字段配置
 *
 * @param data
 * @returns
 */
export async function inviteFieldSettingList(data: any) {
  return request('/apiService/backend/inviteFieldSetting/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取邀请字段配置(分页)
 *
 * @param data
 * @returns
 */
export async function inviteFieldSettingPaginate(data: any) {
  return request('/apiService/backend/inviteFieldSetting/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 编辑邀请字段配置
 *
 * @param data
 * @returns
 */
export async function inviteFieldSettingUpdateOrCreate(data: any) {
  return request('/apiService/backend/inviteFieldSetting/updateOrCreate', {
    data,
    method: 'post',
  });
}
/**
 * 编辑邀请字段配置排序
 *
 * @param data
 * @returns
 */
export async function inviteFieldSettingUpdateSort(data: any) {
  return request('/apiService/backend/inviteFieldSetting/updateSort', {
    data,
    method: 'post',
  });
}

/**
 * 编辑邀请字段配置
 *
 * @param data
 * @returns
 */
export async function inviteFieldSettingDelete(data: any) {
  return request('/apiService/backend/inviteFieldSetting/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取专家领域设置
 *
 * @param data
 * @returns
 */
export async function professionalFieldSettingList(data: any) {
  return request('/apiService/backend/professionalFieldSetting/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取专家领域设置(分页)
 *
 * @param data
 * @returns
 */
export async function professionalFieldSettingPaginate(data: any) {
  return request('/apiService/backend/professionalFieldSetting/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 编辑专家领域设置
 *
 * @param data
 * @returns
 */
export async function professionalFieldSettingUpdateOrCreate(data: any) {
  return request(
    '/apiService/backend/professionalFieldSetting/updateOrCreate',
    {
      data,
      method: 'post',
    }
  );
}

/**
 * 编辑专家领域设置
 *
 * @param data
 * @returns
 */
export async function professionalFieldSettingDelete(data: any) {
  return request('/apiService/backend/professionalFieldSetting/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取会员列表
 *
 * @param data
 * @returns
 */
export async function memberPaginate(data: any) {
  return request('/apiService/backend/member/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取专家项目统计
 *
 * @param data
 * @returns
 */
export async function memberPaginateForCharts(data: any) {
  return request('/apiService/backend/member/paginate/charts', {
    data,
    method: 'get',
  });
}

/**
 * 编辑会员信息
 *
 * @param data
 * @returns
 */
export async function memberUpdateOrCreate(data: any) {
  return request('/apiService/backend/member/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 修改会员信息的排序
 *
 * @param data
 * @returns
 */
export async function memberUpdateSort(data: any) {
  return request('/apiService/backend/member/updateSort', {
    data,
    method: 'post',
  });
}

/**
 * 修改会员信息的排序
 *
 * @param data
 * @returns
 */
export async function memberUpdateStatus(data: any) {
  return request('/apiService/backend/member/updateStatus', {
    data,
    method: 'post',
  });
}

/**
 * 获取会员详情
 *
 * @param data
 * @returns
 */
export async function memberDetail(data: any) {
  return request('/apiService/backend/member/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取邀请申请列表
 *
 * @param data
 * @returns
 */
export async function invitePaginate(data: any) {
  return request('/apiService/backend/invite/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取邀请申请详情
 *
 * @param data
 * @returns
 */
export async function inviteDetail(data: any) {
  return request('/apiService/backend/invite/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取某个用户的评价总览
 *
 * @param data
 * @returns
 */
export async function inviteMemberEvaluationPreview(data: any) {
  return request('/apiService/backend/invite/evaluation/preview', {
    data,
    method: 'get',
  });
}

/**
 * 修改状态和反馈
 *
 * @param data
 * @returns
 */
export async function inviteUpdateStatusAndFeedback(data: any) {
  return request('/apiService/backend/invite/updateStatusAndFeedback', {
    data,
    method: 'post',
  });
}

/**
 * 修改备注
 *
 * @param data
 * @returns
 */
export async function inviteUpdateRemark(data: any) {
  return request('/apiService/backend/invite/updateRemark', {
    data,
    method: 'post',
  });
}

/**
 * 添加实际服务的专家
 *
 * @param data
 * @returns
 */
export async function invitePushRealJoinMembers(data: any) {
  return request('/apiService/backend/invite/pushRealJoinMembers', {
    data,
    method: 'post',
  });
}

/**
 * 移除实际服务的专家
 *
 * @param data
 * @returns
 */
export async function inviteRemoveRealJoinMembers(data: any) {
  return request('/apiService/backend/invite/removeRealJoinMembers', {
    data,
    method: 'post',
  });
}
/**
 * 推送消息
 *
 * @param data
 * @returns
 */
export async function inviteSendNotifySms(data: any) {
  return request('/apiService/backend/invite/sendNotifySms', {
    data,
    method: 'post',
  });
}

/**
 * 删除项目
 *
 * @param data
 * @returns
 */
export async function inviteDelete(data: any) {
  return request('/apiService/backend/invite/delete', {
    data,
    method: 'post',
  });
}

/**
 * 审核用户资料
 *
 * @param data
 * @returns
 */
export async function memberAudit(data: any) {
  return request('/apiService/backend/member/audit', {
    data,
    method: 'post',
  });
}

/**
 * 创建邀请项目附加资料
 *
 * @param data
 * @returns
 */
export async function inviteAttachFormCreate(data: any) {
  return request('/apiService/backend/invite/attachForm/create', {
    data,
    method: 'post',
  });
}

/**
 * 编辑邀请项目附加资料
 *
 * @param data
 * @returns
 */
export async function inviteAttachFormUpdate(data: any) {
  return request('/apiService/backend/invite/attachForm/update', {
    data,
    method: 'post',
  });
}

/**
 * 获取邀请项目附加资料列表
 *
 * @param data
 * @returns
 */
export async function inviteAttachFormList(data: any) {
  return request('/apiService/backend/invite/attachForm/list', {
    data,
    method: 'get',
  });
}

/**
 * 删除邀请项目附加资料
 *
 * @param data
 * @returns
 */
export async function inviteAttachFormDelete(data: any) {
  return request('/apiService/backend/invite/attachForm/delete', {
    data,
    method: 'post',
  });
}
