import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Message, Select } from '@arco-design/web-react';
import styles from './style/index.module.less';
import { profile, profileUpdate } from '@/request/api';

const { useForm } = Form;
const FormItem = Form.Item;
function UpdateUserInfo(props: { onSuccess: () => void; onClose: () => void }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: 0,
    nickname: '',
  });

  const handleSubmit = async () => {
    if (loading) return;
    try {
      await form.validate();
      const values = form.getFieldsValue();
      setLoading(true);

      await profileUpdate({
        account: values.account,
        nickname: userInfo?.nickname,
        oldPassword: values.oldPassword,
        password: values.password,
        id: userInfo?.id,
      });
      Message.success('操作成功');
      form.resetFields();
      props.onSuccess();
    } catch (e) {}
    setLoading(false);
  };

  const loadUserInfo = async () => {
    const response = await profile({});
    const payload = response.data;

    form.setFieldsValue({
      ...payload.profile,
    });

    setUserInfo(payload.profile ?? {});
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  return (
    <div className={styles['search-form-wrapper']}>
      <Form form={form} autoComplete="off" style={{ width: 600 }}>
        <FormItem
          label="账号"
          field="account"
          required
          rules={[
            {
              required: true,
              type: 'string',
              minLength: 2,
              maxLength: 20,
              message: '请输入2-20个字符',
            },
          ]}
        >
          <Input placeholder="请输入账号" disabled />
        </FormItem>
        <FormItem
          label="原密码"
          field="oldPassword"
          required
          rules={[
            {
              required: true,
              type: 'string',
              minLength: 6,
              maxLength: 20,
              message: '请输入6-20个字符',
            },
          ]}
        >
          <Input.Password placeholder="请输入原密码" />
        </FormItem>
        <FormItem
          label="新密码"
          field="password"
          required
          rules={[
            {
              required: true,
              type: 'string',
              minLength: 6,
              maxLength: 20,
              message: '请输入6-20个字符',
            },
          ]}
        >
          <Input.Password placeholder="请输入新密码" />
        </FormItem>

        <FormItem wrapperCol={{ offset: 5 }}>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            style={{ marginRight: 24 }}
          >
            提交
          </Button>
          <Button style={{ marginRight: 24 }} onClick={props.onClose}>
            取消
          </Button>
        </FormItem>
      </Form>
    </div>
  );
}

export default UpdateUserInfo;
