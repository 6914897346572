import {
  Form,
  Input,
  Checkbox,
  Link,
  Button,
  Space,
  Image,
} from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import useStorage from '@/utils/useStorage';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import styles from './style/index.module.less';
import { auth } from '@/request/api';
import LogoFull from '@/assets/logo.jpg';

export default function LoginForm() {
  const formRef = useRef<FormInstance>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginParams, setLoginParams, removeLoginParams] =
    useStorage('loginParams');

  const t = useLocale(locale);

  const [rememberPassword, setRememberPassword] = useState(!!loginParams);

  function afterLoginSuccess(params, accessToken) {
    // 记住密码
    if (rememberPassword) {
      setLoginParams(JSON.stringify(params));
    } else {
      removeLoginParams();
    }
    // 记录登录状态
    localStorage.setItem('token', accessToken);
    // 跳转首页
    window.location.href = '/';
  }

  function login(params) {
    setErrorMessage('');
    setLoading(true);
    auth(params)
      .then((resp) => {
        if (resp.code === 200) {
          afterLoginSuccess(params, resp.data.token);
        } else {
          setErrorMessage(resp.message || t['login.form.login.errMsg']);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onSubmitClick() {
    formRef.current.validate().then((values) => {
      login(values);
    });
  }

  // 读取 localStorage，设置初始值
  useEffect(() => {
    const rememberPassword = !!loginParams;
    setRememberPassword(rememberPassword);
    if (formRef.current && rememberPassword) {
      const parseParams = JSON.parse(loginParams);
      formRef.current.setFieldsValue(parseParams);
    }
  }, [loginParams]);

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']} style={{ textAlign: 'left' }}>
        账号登录
      </div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form
        className={styles['login-form']}
        layout="vertical"
        ref={formRef}
        initialValues={{ account: '', password: '' }}
      >
        <Form.Item
          label={
            <span
              style={{ color: '#1d4e84', fontSize: 16, fontWeight: 'bold' }}
            >
              账号
            </span>
          }
          requiredSymbol={false}
          field="account"
          rules={[{ required: true, message: t['login.form.userName.errMsg'] }]}
        >
          <Input
            prefix={<IconUser style={{ color: '#1d4e84', fontSize: 20 }} />}
            placeholder={t['login.form.userName.placeholder']}
            onPressEnter={onSubmitClick}
            className={styles['login-form-input']}
          />
        </Form.Item>
        <Form.Item
          field="password"
          label={
            <span
              style={{ color: '#1d4e84', fontSize: 16, fontWeight: 'bold' }}
            >
              密码
            </span>
          }
          requiredSymbol={false}
          rules={[{ required: true, message: t['login.form.password.errMsg'] }]}
        >
          <Input.Password
            prefix={<IconLock style={{ color: '#1d4e84', fontSize: 20 }} />}
            placeholder={t['login.form.password.placeholder']}
            onPressEnter={onSubmitClick}
            className={styles['login-form-input']}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <div className={styles['login-form-password-actions']}>
            <Checkbox
              checked={rememberPassword}
              onChange={setRememberPassword}
              className={styles['login-form-password-actions-checkbox']}
            >
              {t['login.form.rememberPassword']}
            </Checkbox>
            {/* <Link>{t['login.form.forgetPassword']}</Link> */}
          </div>
          <Button
            type="primary"
            long
            onClick={onSubmitClick}
            loading={loading}
            style={{
              backgroundColor: '#1d4e84',
            }}
          >
            登录
          </Button>
          {/* <Button
            type="text"
            long
            className={styles['login-form-register-btn']}
          >
            {t['login.form.register']}
          </Button> */}
        </Space>
      </Form>
    </div>
  );
}
