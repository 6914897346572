import React, { useEffect } from 'react';
import Footer from '@/components/Footer';
import LoginForm from './form';
import LoginBanner from './banner';
import styles from './style/index.module.less';
import LoginBackground from '@/assets/login-background.png';
import LoginLogo from '@/assets/login-logo.png';
import Logo from '@/assets/logo-full.jpg';
import { Image } from '@arco-design/web-react';

function Login() {
  useEffect(() => {
    document.body.setAttribute('arco-theme', 'light');
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${LoginBackground})`,
      }}
    >
      <div className={styles.banner}>
        {/* <div className={styles['banner-inner']}>
          <Image src={LoginLogo} width={800} preview={false} />
        </div> */}
      </div>
      <div className={styles.content}>
        {/* <div className={styles['content-logo']}>
          <Image src={Logo} width={250} preview={false} />
        </div> */}
        <div className={styles['content-inner']}>
          <LoginForm />
        </div>
        {/* <div className={styles.footer}>
          <Footer />
        </div> */}
      </div>
    </div>
  );
}
Login.displayName = 'LoginPage';

export default Login;
