import auth, { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
import {
  ExpertUserPermissionCertificateSettingView,
  ExpertUserPermissionConfigView,
  ExpertUserPermissionCredentialsView,
  ExpertUserPermissionEvaluationSettingView,
  ExpertUserPermissionExpertAudit,
  ExpertUserPermissionExpertView,
  ExpertUserPermissionInviteFieldSettingView,
  ExpertUserPermissionInviteView,
  ExpertUserPermissionProfessionalFieldSettingView,
  PermissionRoleView,
  PermissionUserView,
} from './utils/constants';

export type IRoute = AuthParams & {
  name: string;
  key: string;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
};

export const routes: IRoute[] = [
  {
    name: '欢迎页',
    key: 'welcome',
    requiredPermissions: [ExpertUserPermissionExpertView],
  },
  {
    name: '专家管理',
    key: 'expert',
    requiredPermissions: [ExpertUserPermissionExpertView],
    oneOfPerm: true,
    children: [
      {
        name: '专家列表',
        requiredPermissions: [ExpertUserPermissionExpertView],
        oneOfPerm: false,
        key: 'member/index',
      },
      {
        name: '专家编辑',
        requiredPermissions: [ExpertUserPermissionExpertView],
        oneOfPerm: false,
        ignore: true,
        key: 'member/update',
      },
      {
        name: '专家资料审核',
        requiredPermissions: [ExpertUserPermissionExpertAudit],
        oneOfPerm: false,
        ignore: true,
        key: 'member/audit',
      },
      {
        name: '专家项目统计',
        requiredPermissions: [ExpertUserPermissionExpertView],
        oneOfPerm: false,
        key: 'member/chartsList',
      },
      {
        name: '电子证书',
        requiredPermissions: [ExpertUserPermissionCredentialsView],
        oneOfPerm: false,
        key: 'credentials/index',
      },
    ],
  },
  {
    name: '邀请管理',
    key: 'invite',
    requiredPermissions: [
      ExpertUserPermissionInviteView,
      ExpertUserPermissionExpertView,
    ],
    oneOfPerm: true,
    children: [
      {
        name: '邀请申请',
        requiredPermissions: [ExpertUserPermissionInviteView],
        oneOfPerm: false,
        key: 'invite/index',
      },
      {
        name: '会员列表',
        requiredPermissions: [ExpertUserPermissionExpertView],
        oneOfPerm: false,
        key: 'member/memberList',
      },
    ],
  },
  {
    name: '基础信息维护',
    key: 'baseInfo',
    requiredPermissions: [
      ExpertUserPermissionCertificateSettingView,
      ExpertUserPermissionProfessionalFieldSettingView,
      ExpertUserPermissionEvaluationSettingView,
      ExpertUserPermissionInviteFieldSettingView,
    ],
    oneOfPerm: true,
    children: [
      {
        name: '资格证书配置',
        requiredPermissions: [ExpertUserPermissionCertificateSettingView],
        oneOfPerm: false,
        key: 'baseInfo/certificate',
      },
      {
        name: '专家领域配置',
        requiredPermissions: [ExpertUserPermissionProfessionalFieldSettingView],
        oneOfPerm: false,
        key: 'baseInfo/professional',
      },
      {
        name: '服务评价配置',
        requiredPermissions: [ExpertUserPermissionEvaluationSettingView],
        oneOfPerm: false,
        key: 'baseInfo/evaluation',
      },
      {
        name: '邀请资料配置',
        requiredPermissions: [ExpertUserPermissionInviteFieldSettingView],
        oneOfPerm: false,
        key: 'baseInfo/inviteField',
      },
    ],
  },
  {
    name: '系统设置',
    key: 'setting',
    requiredPermissions: [
      ExpertUserPermissionConfigView,
      PermissionUserView,
      PermissionRoleView,
    ],
    oneOfPerm: true,
    children: [
      {
        name: '用户管理',
        requiredPermissions: [PermissionUserView],
        oneOfPerm: false,
        key: 'user/index',
      },
      {
        name: '角色管理',
        requiredPermissions: [PermissionRoleView],
        oneOfPerm: false,
        key: 'role/index',
      },
      {
        name: '系统设置',
        key: 'setting/setting',
        requiredPermissions: [ExpertUserPermissionConfigView],
        oneOfPerm: false,
      },
    ],
  },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

const useRoute = (userPermission): [IRoute[], string] => {
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);

  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
